import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./pages/App";
import Backlog from "./pages/Backlog";
import BacklogBP from "./pages/BacklogBp";
import PNps from "./pages/pNps";
import PNpsCNPJ from "./pages/pNpsCNPJ";
import Login from "./pages/Login";
import WhatsBtn from "./Components/WhatsBtn";
import SaveProduct from "./pages/SaveProduct";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router> 
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/nps/:cpfCnpj" element={<PNps />} /> 
        <Route path="/backoffice" element={<Backlog />}/>
        <Route path="/save-product" element={<SaveProduct />}/>
        <Route path="/bp.backoffice" element={<BacklogBP />}/>
        <Route path="/npscnpj/:cpfCnpj" element={<PNpsCNPJ />}/>
        <Route path="/login" element={<Login />}/>
      </Routes>
      <WhatsBtn/>
    </Router>
  </React.StrictMode>
);
