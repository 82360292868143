import { React, useEffect, useState } from 'react';
import axios from '../services/axios'
import routes from '../routes';
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavbarLog from "../Components/NavbarLog";
import TradingV from "../Components/Trading";
import ProductListFazCredito from "../Components/ProductListFazCredito";
import jwtValidator from '../functions/JWTTokenValidator'
import logoFazCredito from "../Assets/Logotipo.png";
import "../App.css";
import iconUser from "../Assets/do-utilizador.png";
import iconCard from "../Assets/Credit Card.png";
import iconPhone from "../Assets/iPhone 14.png";
import iconEye from "../Assets/Eye.png";
import { formatDate, formatPhone, formatCPF, formatCNPJ, formatMoney } from '../functions/formater';
import { IoSearchSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";



import "../App.css";
import encrypt from '../functions/Encrypt';
import Loading from '../Components/Loading';

function Backlog() {
  const navigate = useNavigate();
  const [produtos, setProdutos] = useState(null)
  const [nome, setNome] = useState(null)
  const [telefone, setTelefone] = useState(null)
  const [documento, setDocumento] = useState(null)
  const [cnpjBusca, setCnpjBusca] = useState(null)
  const [produtosEmpresa, setProdutosEmpresa] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [qtdProdutos, setQtdProdutos] = useState(0);

  useEffect(() => {
    setLoading(true)
    validateToken()
    setData()
    setLoading(false)
  }, []);

  const changeCnpjBusca = (event) => {
    return setCnpjBusca(event.target.value);
  };

  const setData = async () => {
    setNome(localStorage.getItem("nome"));
    setDocumento(localStorage.getItem("documento"));
    setTelefone(localStorage.getItem("telefone"));
  };

  const validateToken = async () => {
    const token = localStorage.getItem("token")
    if (!token) {
      return navigate("/login")
    }
    const valido = await jwtValidator(token)

    if (!valido) {
      localStorage.removeItem('token');
      return navigate("/login")
    }
  }

  const findProductsEmpresa = async () => {
    if (!cnpjBusca) return alert("Informe um cnpj para buscar")

    try {
      setLoading(true)
      const docBusca = await encrypt(cnpjBusca)
      const request = await axios.get(routes.findProdutosByCNPJ + docBusca);
      if (request.data.produtos) {
        setProdutosEmpresa(request.data.produtos);
        setQtdProdutos(request.data.qtdProdutosBanco)
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="App">
      {isLoading && <Loading />}
      <NavbarLog />

      <div className="backBPArea">
        <div className="areaLeft">
          <div className="logoLeft">
            <img src={logoFazCredito} alt="" />
          </div>
          <div className="dadosLeft">
            <h1>
              <img src={iconUser} alt="" />
              <span className='infoUserLogged'> {nome} </span>
            </h1>
            <h1>
              <img src={iconCard} alt="" />
              <span className='infoUserLogged'> {formatCPF(documento)} </span>
            </h1>
            <h1>
              <img src={iconPhone} alt="" />
              <span className='infoUserLogged'>  {formatPhone(telefone)} </span>
            </h1>
          </div>

          <a href="/save-product" target="_blank">
            <FaPlus />
            <span className='infoUserLogged'> Novo Produto </span>
          </a>
        </div>
        <div className="areaRight">
          <div className="cabecalhoBusca">
            <h1>Buscar Empresa</h1>
            <input
              type="text"
              placeholder="Informe o CNPJ da empresa ou associação para buscar"
              value={cnpjBusca}
              onChange={changeCnpjBusca}
            />
            <button type="button" className="btn btn-primary button-busca-associado" onClick={findProductsEmpresa}>
              <IoSearchSharp />
            </button>
          </div>

          {produtosEmpresa.length > 1 && (
            <ProductListFazCredito produtos={produtosEmpresa} qtdProdutos={qtdProdutos} />
          )}

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Backlog;