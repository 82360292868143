import { useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from 'axios';
import routes from "../routes";
import ProductsList from "./ProductsList"
import Loading from './Loading';


const NpsProdutos = ({ cliente }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelected = async (data) => {
    setIsLoading(true)
    try {
      const obj = {
        privado: cliente.isPrivado,
        cpf: cliente.cpf,
        produto: data.title,
        type: "solicitacaoCompra",
        destinatario: data.destinatario
      }

      if (data.title === "Consignado Privado" && cliente.isPrivado === 1) {
        await Promise.all([
          axios.post(routes.saveProdutoConsignado, obj),
          axios.post(routes.sendMail, obj)
        ]).then(() => {
          Swal.fire({
            icon: "success",
            title: "Sucesso!!",
            text: "E-mail enviado para nosso time de especialistas",
          })
        })
      } else {
        axios.post(routes.sendMail, obj).then(() => {
          Swal.fire({
            icon: "success",
            title: "Sucesso!!",
            text: "E-mail enviado para nosso time de especialistas",
          })
        })
      }

    } catch {
      Swal.fire({
        icon: "error",
        title: "Opss",
        text: "Erro ao enviar e-mail para nosso time de especialistas",
      })
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="produtos-section-wrapper" id="solucoes">
        <div className="produtos-section-top">
          <h1 className="primary-heading-npsprod">Quero Mais!!!</h1>
          <p className="primary-text-npsprod">Produtos e serviços</p>
        </div>
        <div className="produtos-section-bottom">
          {ProductsList.map((data, index) => (
            <div className="produtos-section-info" key={index}>
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" className="produtos-img-card" />
              </div>
              <h3>{data.title}</h3>
              <button
                className="terciary-button button-send-mail"
                onClick={() => {
                  handleSelected(data);
                }}
              >
                Adquira já
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NpsProdutos;
