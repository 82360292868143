import { React, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavbarLog from "../Components/NavbarLog";
import TradingV from "../Components/Trading";
import FormBacklog from "../Components/FormBacklog";
import jwtValidator from '../functions/JWTTokenValidator'

import "../App.css";

function SaveProduct() {
    const navigate = useNavigate();

    useEffect(() => {
        validateToken()
    }, []);

    const validateToken = async () => {
        const token = localStorage.getItem("token")
        if (!token) {
            return navigate("/login")
        }
        const valido = await jwtValidator(token)

        if (!valido) {
            localStorage.removeItem('token');
            return navigate("/login")
        }
    }
    return (
        <div className="App">
            <NavbarLog />
            <TradingV />
            <FormBacklog />
            <Footer />
        </div>
    );
}

export default SaveProduct;