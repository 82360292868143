const track = (botaoTrack, localTrack) => {

    window._paq.push([
        "trackEvent",
        "Click",
        botaoTrack,
        localTrack
    ]);
};

export default track;