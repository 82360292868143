import axios from '../services/axios'
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import routes from '../routes';
import { formatDate, formatPhone, formatCPF, formatCNPJ, formatMoney } from '../functions/formater';
import Swal from 'sweetalert2';
import Loading from './Loading';


const CustomModal = ({ visible, onClose, produto, banco = false }) => {
  const [selectedOption, setSelectedOption] = useState(banco ? produto.statusBanco : produto.statusEmpresa);
  const [isLoading, setLoading] = useState(false);

  if (!visible) {
    return null;
  }

  async function onSave() {
    setLoading(true)
    const dados = {
      Id: produto.Id,
      nome: produto.nome,
      telefone: produto.celular,
      cpf: produto.cpf,
      email: produto.email,
      valor: produto.valor,
      type: "aprovacaoconsignado",
      ...(banco ? { statusBanco: selectedOption } : { statusEmpresa: selectedOption })
    };

    await axios.post(routes.saveChangeStatusEmpresa, dados)
      .then(async () => {
        setLoading(false); 
        onClose();
        return Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Status do consiganado alterado.",
          showConfirmButton: false,
          timer: 1000
        })
      })
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="custom-modal-overlay">
          <div className="custom-modal-content">
            <div className="custom-modal-upper-content">
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" disabled value={produto.nome} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Documento</Form.Label>
                  <Form.Control type="text" disabled value={formatCPF(produto.cpf)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Diretoria</Form.Label>
                  <Form.Control type="text" disabled value={produto.diretor} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Data Soliciatação</Form.Label>
                  <Form.Control type="text" disabled value={formatDate(produto.CreatedAt)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Valor solicitado</Form.Label>
                  <Form.Control type="text" disabled value={formatMoney(produto.valor)} />
                </Form.Group>
              </Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Status Banco</Form.Label>
                <Form.Select
                  className="form-select form-select-lg mb-3"
                  // disabled={disabledSelect}
                  aria-label=".form-select-lg example"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="analise">Análise</option>
                  <option value="aprovado">Aprovado</option>
                  <option value="recusado">Recusado</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="custom-lower-modal-content">
              <button onClick={onSave} className="custom-save-button">
                Salvar
              </button>
              <button onClick={onClose} className="custom-close-button">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;