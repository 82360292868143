import React, { useState, useEffect } from 'react';
import CustomModal from "./CustomModal";
import logoFazCredito from "../Assets/Logotipo.png";
import "../App.css";
import iconUser from "../Assets/do-utilizador.png";
import iconCard from "../Assets/Credit Card.png";
import iconPhone from "../Assets/iPhone 14.png";
import iconEye from "../Assets/Eye.png";
import TradingV from './Trading';
import Swal from "sweetalert2";
import Card from 'react-bootstrap/Card';
import { formatDate, formatPhone, formatCPF, formatCNPJ, formatMoney } from '../functions/formater';
import Badge from 'react-bootstrap/Badge';

const FormBacklogBP = ({ produtos, qtdProdutos }) => {
  const [nome, setNome] = useState(null)
  const [telefone, setTelefone] = useState(null)
  const [documento, setDocumento] = useState(null)
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [produtosFiltrados, setProdutosFiltrados] = useState(produtos);
  const [activeFilter, setActiveFilter] = useState('todas'); // Estado para o filtro ativo
  const [showMessage, setShowMessage] = useState(false)
  const [filtrosData, setFiltrosData] = useState(false)



  useEffect(() => {
    const initialize = async () => {
      await setData();
    };

    initialize();
  }, []);


  const setData = async () => {
    if (!produtos) setShowMessage(true)

    setNome(localStorage.getItem("nome"));
    setDocumento(localStorage.getItem("documento"));
    setTelefone(localStorage.getItem("telefone"));
  };

  const handleOpenCustomModal = (produto) => {
    setSelectedProduto(produto);
  };

  const handleCloseCustomModal = () => {
    setSelectedProduto(null);
  };

  const filtrarProdutos = (filter) => {
    setActiveFilter(filter);
    if (showMessage) return

    let prodFilter = produtos

    if (filtrosData) {
      prodFilter = produtosFiltrados
    }

    if (filter) {
      setProdutosFiltrados(prodFilter.filter(product => product.statusFinal.includes(filter)));
    } else {
      setProdutosFiltrados(prodFilter);
    }
  };


  async function filtrarPorData() {
    setFiltrosData(true)
    if (!dataInicial) {
      return Swal.fire({
        icon: "info",
        title: "Atenção!",
        text: "Informe a data inicial",
      })
    }

    const inicio = new Date(dataInicial);
    const fim = dataFinal ? new Date(dataFinal) : new Date();


    if (inicio > fim) {
      return Swal.fire({
        icon: "info",
        title: "Atenção!",
        text: "A data final não pode ser menor que a data inicial!",
      })
    }

    const filtrados = produtos.filter((produto) => {
      const dataProduto = new Date(produto.CreatedAt);
      return dataProduto >= inicio && dataProduto <= fim;
    });

    return setProdutosFiltrados(filtrados);
  }

  function handleDynamicFilter(inputValue) {
    if (inputValue.length >= 3) {
      const filtrados = produtos.filter((produto) => {
        return (
          produto.nome.toLowerCase().includes(inputValue.toLowerCase()) ||
          produto.diretor.toLowerCase().includes(inputValue.toLowerCase()) ||
          produto.cpf.includes(inputValue)
        );
      });

      setProdutosFiltrados(filtrados);
    } else if (inputValue.length === 0) {
      setProdutosFiltrados(produtos);
    }
  }

  function limparFiltros() {
    setDataInicial('');
    setDataFinal('');

    setFiltrosData(false)

    setProdutosFiltrados(produtos);
  }

  function getBadgeClass(status) {
    if (status === 'analise') return 'primary';
    if (status === 'recusado') return 'danger';
    if (status === 'aprovado') return 'success';
  }

  function getStatusText(status) {
    if (status === 'analise') return 'Análise';
    if (status === 'recusado') return 'Recusado';
    if (status === 'aprovado') return 'Aprovado';
  }


  return (
    <div className="backBPArea">
      <div className="areaLeft">
        <div className="logoLeft">
          <img src={logoFazCredito} alt="" />
        </div>
        <div className="dadosLeft">
          <h1>
            <img src={iconUser} alt="" />
            <span className='infoUserLogged'> {nome} </span>
          </h1>
          <h1>
            <img src={iconCard} alt="" />
            <span className='infoUserLogged'> {formatCPF(documento)} </span>
          </h1>
          <h1>
            <img src={iconPhone} alt="" />
            <span className='infoUserLogged'>  {formatPhone(telefone)} </span>
          </h1>
        </div>
      </div>
      <div className="areaRight">
        <div className="cabecalhoBusca">
          <h1>Buscar Cliente</h1>
          <input
            className="iconInput"
            type="text"
            placeholder="Busque por nome, documento ou diretor"
            onChange={(e) => handleDynamicFilter(e.target.value)}
          />
        </div>
        <div className="form-line">
          <label>Inicial:</label>
          <input
            className="calendario-input"
            type="date"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
          <label>Final:</label>
          <input
            className="calendario-input"
            type="date"
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />
          <button type="button" className="btn btn-primary" style={{ padding: '5px 10px' }} onClick={filtrarPorData}>Filtrar</button>
          <button type="button" className="btn btn-danger" style={{ padding: '5px 10px' }} onClick={limparFiltros}>Limpar</button>

        </div>

        <div className="tabelaDados">
          <div className="buttonsTabela">
            <button className={`button-backoffice ${activeFilter === 'aprovado' ? 'active' : ''}`} onClick={() => filtrarProdutos(activeFilter === 'aprovado' ? '' : 'aprovado')}>Aprovado/{qtdProdutos.aprovado}</button>
            <button className={`button-backoffice ${activeFilter === 'recusado' ? 'active' : ''}`} onClick={() => filtrarProdutos(activeFilter === 'recusado' ? '' : 'recusado')}>Recusado/{qtdProdutos.recusado}</button>
            <button className={`button-backoffice ${activeFilter === 'analise' ? 'active' : ''}`} onClick={() => filtrarProdutos(activeFilter === 'analise' ? '' : 'analise')}>Análise/{qtdProdutos.analise}</button>
          </div>
          {showMessage && produtosFiltrados.length < 1 ? (
            <div>
              <Card bg='secondary' body className="text-center card-bootstrap">Não há consignados cadastrados, verifique os filtros informados!</Card>
            </div>
          ) : (
            <table className="table-class">
              <thead>
                <tr className="cabecalhoTabela">
                  <th className="thTabelaImg"></th>
                  <th className="thTabela">Funcionario</th>
                  <th className="thTabela">CPF</th>
                  <th className="thTabela">Diretoria</th>
                  <th className="thTabela">Produto</th>
                  <th className="thTabela">Data Solic</th>
                  <th className="thTabela">Valor</th>
                  <th className="thTabela">Banco</th>
                  <th className="thTabela">Empresa</th>
                  <th className="thTabela">Status Final</th>
                </tr>
              </thead>
              <tbody className="tbody-css2">
                {produtosFiltrados.map((item, index) => (
                  <tr className={`cabecalhoTabela ${index % 2 === 0 ? 'gray-row' : 'white-row'}`} key={index}>
                    <td className="tdTabelaImg">
                      <img src={iconEye} alt="Descrição da imagem" onClick={() => handleOpenCustomModal(item)} />
                    </td>
                    <td className="tdTabela">{item.nome}</td>
                    <td className="tdTabela">{formatCPF(item.cpf)}</td>
                    <td className="tdTabela">{item.diretor}</td>
                    <td className="tdTabela">Consignado</td>
                    <td className="tdTabela">{formatDate(item.CreatedAt)}</td>
                    <td className="tdTabela">{formatMoney(item.valor)}</td>
                    <td className="tdTabela">
                      <h6> <Badge bg={getBadgeClass(item.statusBanco)}> {getStatusText(item.statusBanco)}</Badge> </h6>
                    </td>
                    <td className="tdTabela">
                      <h6> <Badge bg={getBadgeClass(item.statusEmpresa)}> {getStatusText(item.statusEmpresa)}</Badge> </h6>
                    </td>
                    <td className="tdTabela">
                      <h6> <Badge bg={getBadgeClass(item.statusFinal)}> {getStatusText(item.statusFinal)}</Badge> </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {selectedProduto && (
        <CustomModal
          visible={!!selectedProduto}
          onClose={handleCloseCustomModal}
          produto={selectedProduto}
        />
      )}
    </div>
  );
};

export default FormBacklogBP;