import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../routes"
import axios from '../services/axios'
import Swal from 'sweetalert2'
import FormLogin from "./FormLogin";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from './Loading';
import encrypt from '../functions/Encrypt'
import { cpf } from 'cpf-cnpj-validator';

const LoginModalSendMail = ({ isOpen, onClose, item }) => {
  const navigate = useNavigate();
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [telefone, setTelefone] = useState("");
  const [showFormLogin, setShowFormLogin] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const mascara = cpfCnpj.replace(/\D/g, '').length <= 11 ? "999.999.999-99" : "99.999.999/9999-99";


  const somenteNumeros = (valor) => {
    return valor.replace(/\D/g, ''); // Remove tudo que não for número
  };

  const handleChangeCpfCnpj = (event) => {
    const valorSemMascara = somenteNumeros(event.target.value);
    setCpfCnpj(valorSemMascara);
  };

  const handleChangeTelefone = (event) => {
    setTelefone(event.target.value);
  };

  const closeModal = (event) => {

  }

  const handleConfirm = async () => {
    if (!cpfCnpj || cpfCnpj === "" || !await cpf.isValid(cpfCnpj)) {
      return Swal.fire({
        icon: "error",
        title: "",
        text: "Preencha corretamente o campo de CPF/CNPJ para efetuar o login.",
      });
    }

    if (!recaptchaValue) {
      return Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Por favor, complete o reCAPTCHA.",
      });
    }

    try {
      const encriptado = await encrypt(cpfCnpj);
      const request = await axios.get(routes.authentication + encriptado);
      setCliente(request.data); // setCliente é síncrono, não precisa de await nem .then()

      Swal.fire({
        icon: "info",
        title: "Informe os dados para contato",
        html: `
          <div class="swal-area">
            <h4>Nome</h4>
            <input id="nomeCliente" class="input-swal" type="text" value="${request.data.nome || ''}">
            <h4>Observação</h4>
            <input id="associacaoCliente" class="input-swal" type="text" value="${request.data.associacao || ''}">
            <h4>Telefone</h4>
            <input id="telefoneCliente" class="input-swal" type="number" value="${request.data.celular || ''}">
            <h4>Documento</h4>
            <input id="cpfCliente" class="input-swal" type="number" value="${cpfCnpj}">
            <h4>Email</h4> 
            <input id="emailCliente" class="input-swal" type="text" value="${request.data.email || ''}">
          </div>
          <h3>Encaminhe para nosso time de especialistas</h3>
        `,
        showDenyButton: true,
        confirmButtonText: "Enviar",
        confirmButtonColor: "#ffce10",
        denyButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const nome = document.getElementById('nomeCliente').value;
          const associacao = document.getElementById('associacaoCliente').value;
          const telefone = document.getElementById('telefoneCliente').value;
          const cpf = document.getElementById('cpfCliente').value;
          const email = document.getElementById('emailCliente').value;

          if (!nome || !telefone || !cpf || !email) {
            return Swal.fire({ icon: "info", title: "Atenção", text: "Preencha todos os campos para prosseguir", timer: 2000 });
          }

          const obj = {
            privado: cliente.isPrivado,
            nome,
            associacao,
            celular: telefone,
            cpf,
            email,
            produto: item.title,
            produto_type: item.destinatario,
            type: "solicitacaoCompra",
          };


          if (item.title === "Consignado Privado" && cliente.isPrivado === 1) {
            await Promise.all([
              axios.post(routes.saveProdutoConsignado, obj),
              axios.post(routes.sendMail, obj)
            ]).then(() => {
              Swal.fire({
                icon: "success",
                title: "Sucesso!!",
                text: "E-mail enviado para nosso time de especialistas",
              }).then(async () => {
                return navigate(`/nps/${encriptado}`);
              })
            })
          } else
            await axios.post(routes.sendMail, obj).then(() => {
              if (cliente) {
                return navigate(`/nps/${encriptado}`);
              }
              else {
                return Swal.fire({
                  icon: "success",
                  title: "E-mail enviado",
                  text: "Em breve nosso time de especialistas entrará em contato.",
                  timer: 2500,
                });
              }
            });
        } else if (result.isDenied) {
          closeModal(true);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCliente(null);
      setIsLoading(false);
    }

  };


  function onRecaptchaChange(value) {
    setRecaptchaValue(value);
  }

  return (
    <div className="loginModalBackground">
      {isLoading && <Loading />}
      <div className="loginModalContainer">
        <div className="loginModalTitleCLoseBtn"> 
          <button onClick={() => onClose(false)}>X</button>
        </div>
        <div className="loginModalContentBody">
          <input
            className="input-login"
            type="text"
            value={cpfCnpj}
            onChange={handleChangeCpfCnpj}
            placeholder="Informe seu CPF/CNPJ"
          />
          <ReCAPTCHA
            sitekey="6Lf1UfYpAAAAALif4SceIRzsz9x5dYv1nuHa9pyU"
            onChange={onRecaptchaChange}
          />
          <div className="loginModalContentButtons">
            <button onClick={handleConfirm}>Validar CPF/CNPJ</button>
          </div>
        </div>
      </div>
      {showFormLogin && <FormLogin cliente={cliente} onClose={() => setShowFormLogin(false)} />}
    </div>

  );
};

export default LoginModalSendMail;
