import React, {useState} from "react";
import { FiArrowRight } from "react-icons/fi";
import LoginModal from "./LoginModal";
import track from "../services/track";

const Home = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    track("Entrar", "Hero")
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {modalOpen === true && (<LoginModal isOpen={modalOpen} closeModal={closeModal}/>)  }
      <div className="home-container">
        <div className="home-banner-container">
          <div className="home-text-section"> 
            <h1 className="primary-heading">
            Descubra soluções financeiras feitas <br/><span className="home-text-span">para você</span>
            </h1>
            <p className="primary-text-home">
            De refinanciamento a investimentos, oferecemos soluções adaptadas às suas necessidades individuais.
            </p>
            <button onClick={openModal} className="secondary-button" >
              Entrar<FiArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
